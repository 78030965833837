var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"\n    px-6\n    d-flex align-center justify-center\n  ",style:(_vm.sheetStyle),attrs:{"dark":_vm.dark,"color":_vm.color}},[_c('div',{staticClass:"flex-grow-1 d-flex align-center",class:{
      'flex-row-reverse': !_vm.imgRight,
      'flex-column': _vm.isMobile,
    },style:({
      'max-width': '1200px',
      'min-height': _vm.small ? '0':_vm.height,
    })},[_c('div',{staticClass:"flex-grow-1 py-6"},[(_vm.title)?_c('div',{staticClass:"pb-2 font-weight-black",class:{
          'primary--text': !_vm.dark,
          'text-center': _vm.center,
          'text-h4': _vm.isMobile,
          'text-h3': !_vm.isMobile,
        }},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_vm._t("default")],2),(!!_vm.img && (!_vm.isMobile || _vm.imgMobile == 'bottom'))?[_c('v-img',{class:(!_vm.isMobile ? (_vm.imgRight ? 'ml-8':'mr-8'):'')
          + ' '
          + _vm.imgClass,attrs:{"width":_vm.isMobile ? '300px':_vm.imgWidth,"height":(_vm.isMobile || _vm.imgMobile != 'bottom') ? undefined:_vm.height,"position":_vm.imgMobile == 'bottom' ? 'bottom':'center',"src":_vm.$assets(_vm.img),"contain":""}})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }