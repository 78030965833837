<template>
  <div>

    <v-app-bar
      app
      color="primary darken-2"
      elevate-on-scroll
      height="80px"
    >
      <div style="margin: 0 auto; max-width: 1200px" class="flex-grow-1 d-flex align-center">
        <div>
          <v-img
            src="/tesys-white.png"
            width="125px"
            height="50px"
            contain
          />
        </div>
        <v-spacer />
        <div class="mr-1">
          <v-btn
            depressed color="primary lighten-1"
            href="https://wa.me/554840420601"
            target="_blank"
          >
            saiba mais
          </v-btn>
          <v-btn
            v-if="$vuetify.breakpoint.width > 800"
            class="ml-5"
            :to="{name: 'login'}"
          >
            Área de Usuário
          </v-btn>
        </div>
      </div>
    </v-app-bar>

    <Content
      color="white" dark
      title="Melhore a qualidade de seus serviços contábeis"
      img="./home/people1.png"
      img-right
      img-class="fill-height"
      img-mobile="bottom"
      :sheetStyle="{
        boxShadow: `inset 0 0 0 500px rgba(0, 0, 0, .7)`,
        backgroundImage: `url(${$assets('./home/bg1.png')})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'black',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
      height="400px"
    >
      <div class="font-weight-medium">
        O <b class="primary--text text--lighten-2">Tesys</b> foi desenvolvido para trazer mais facilidade para o Dia a Dia.
        O seu Escritório de Contabilidade tem um novo parceiro.
      </div>
    </Content>

    <Content
      color="primary darken-1"
      dark
      small
    >
      <div class="d-flex align-center">
        <div class="flex-grow-1 text-subtitle-1 font-weight-bold">
          Quer agendar uma apresentação com nossa equipe?
        </div>
        <v-btn
          color="white primary--text"
          class="ml-4"
          href="https://wa.me/554840420601"
          target="_blank"
        >
          Entrar em Contato
        </v-btn>
      </div>
    </Content>

    <Content
      color="white"
      title="Ofereça Serviços Exclusivos para seus Clientes"
      img="./home/people2.png"
      img-class="elevation-5 rounded-md"
      imgWidth="592px"
    >
      O <b class="primary--text">Tesys</b> foi desenvolvido para trazer mais facilidade para o Dia a Dia.
      O seu Escritório de Contabilidade tem um novo parceiro.
      <div
        v-for="(service, i) in services" :key="i"
        class="d-flex align-center mt-4"
      >
        <v-icon small class="
          primary white--text
          rounded-circle
          pa-2 mr-4
        ">
          {{ service.icon }}
        </v-icon>
        {{ service.text }}
      </div>
    </Content>

    <Content
      color="primary" dark
      title="Funcionalidades Exclusivas do Tesys"
      center
      :sheetStyle="{
        boxShadow: `inset 0 0 0 500px rgb(0, 131, 144, .8)`,
        backgroundImage: `url(${$assets('./home/bg2.png')})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'black',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <v-row no-gutters class="mt-4">
        <v-col
          v-for="(funct, i) in functionalities" :key="i"
          :cols="12"
          :sm="6"
        >
          <div class="d-flex align-start ma-4">
            <div class="rounded-lg primary darken-4 pa-4">
              <v-img
                v-if="funct.img"
                max-width="35px"
                max-height="35px"
                :src="$assets(`./menu/${funct.img}.png`)"
                contain
              />
              <v-icon v-else size="35" color="white">
                {{ funct.icon }}
              </v-icon>
            </div>
            <div class="ml-4 mt-n1">
              <div class="text-h6 font-weight-bold">
                {{ funct.title }}
              </div>
              <div class="text-body-1">
                {{ funct.text }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </Content>

    <Content
      color="white"
      title="Últimas Notícias do Tesys"
      center
      class="py-8"
    >
      <v-row no-gutters>
        <v-col
          v-for="(item, i) in news" :key="i"
          :cols="12"
          :md="6"
        >
          <div class="pa-4 fill-height">
            <v-card class="fill-height" :href="item.link" target="_blank">
              <div
                class="
                  grey darken-4
                  rounded-md
                  d-flex align-center justify-center
                "
                style="overflow: hidden"
              >
                <v-img
                  :src="item.img"
                  height="200px"
                  width="200px"
                  contain
                />
              </div>
              <div class="pa-4">
                <div class="text-h6">
                  {{ item.title }}
                </div>
                <p class="mb-0">
                  {{ item.description }}
                </p>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <div class="my-4" />
    </Content>

    <!-- Vídeo -->
    <!-- <Content color="grey lighten-3">
      <v-responsive
        :aspect-ratio="16/9"
        class="red"
      >
        <div style="width: 100%">
        </div>
      </v-responsive>
    </Content> -->



    <Content color="grey lighten-4" height="350px">
      <v-row align="center" justify="center">
        <v-col cols="12" md="4" align="center">
          <v-img
            src="/tesys-blue.png"
            width="250px"
            contain
          />
        </v-col>
        <v-col cols="12" md="4" align="center">
          <span class="primary--text default-home-title text-h4">
            Fale Conosco
          </span>
          <br/>
          <span class="default-home-text text-h6">
            Estamos dispostos a respondê-lo
          </span>
          <br/>
          <v-chip
            color="white"
            class="mt-3 elevation-1"
            target="_blank"
            href="mailto:contato@almeidatech.com"
          >
            <v-icon small color="primary" class="mr-2">mdi-email</v-icon>
            contato@almeidatech.com
          </v-chip>
          <br />
          <v-chip color="white" class="my-3 elevation-1" href="tel:+554840420601">
            <v-icon small color="primary" class="mr-2">mdi-phone</v-icon>
            (48) 4042-0601
          </v-chip>
          <br />
          <!-- <v-btn
            rounded color="white" class="mx-1"
            target="_blank" href="https://facebook.com/"
          >
            <v-icon color="primary">mdi-facebook</v-icon>
          </v-btn> -->
          <v-btn
            small fab :elevation="2" color="white" class="mx-1"
            target="_blank" href="https://instagram.com/tesysbr/"
          >
            <v-icon color="primary">mdi-instagram</v-icon>
          </v-btn>
          <v-btn
            small fab :elevation="2" color="white" class="mx-1"
            target="_blank" href="https://wa.me/554840420601"
          >
            <v-icon color="primary">mdi-whatsapp</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" align="center">
          <v-card max-width="500px">
            <v-form ref="form" class="pa-3">
              <v-text-field
                class="my-1"
                outlined
                dense
                hide-details="auto"
                label="Nome *"
                :rules="[$rules.required]"
              />
              <div class="d-flex my-1">
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  label="E-Mail *"
                  :rules="[$rules.email, $rules.required]"
                />
                <v-text-field
                  hide-details="auto"
                  outlined
                  dense
                  class="ml-1"
                  style="max-width: 200px"
                  label="Telefone *"
                  placeholder="(00) 00000-0000"
                  v-mask="['(##) #####-####']"
                  :rules="[$rules.phone, $rules.required]"
                />
              </div>
              <v-text-field
                class="my-1"
                dense
                hide-details="auto"
                outlined
                label="Assunto *"
                :rules="[$rules.required]"
              />
              <v-textarea
                class="my-1"
                rows="2"
                auto-grow
                hide-details="auto"
                outlined
                dense
                label="Mensagem *"
                :rules="[$rules.required]"
              />
              <div class="d-flex justify-end mt-3">
                <v-btn color="primary" @click="send()">Enviar</v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </Content>


    <Footer />

  </div>
</template>

<script>
import Content from '@/components/home/Content.vue';
import Footer from '../components/home/Footer.vue';

export default {
  name: "Home",

  components: {
    Content,
    Footer,
  },

  data: () => ({
    services: [
      {
        icon: "mdi-laptop",
        text: "Controle de Caixa dos negócios",
      },
      {
        icon: "mdi-folder",
        text: "Armazene e Gerencie Documentos",
      },
      {
        icon: "mdi-file-document-outline",
        text: "Faça solicitações de forma segura e ágil",
      },
      {
        icon: "mdi-account-group",
        text: "Melhore toda a gestão de maneira facilitada",
      },
    ],
    functionalities: [
      {
        img: "icone_caixa",
        title: "Fluxo de Caixa",
        text: "Controle o Caixa do seu Negócio através de uma tela intuitiva e Gráﬁcos de fácil leitura",
      },
      {
        icon: "mdi-form-select",
        title: "Formulários",
        text: "Faça solitações de Informações ou Documentos através de formulários online",
      },
      {
        img: "icone_docs",
        title: "Documentos",
        text: "Gerencie Documentos e os armaneze em um só lugar",
      },
      {
        img: "icone_malha_fina",
        title: "Malha Fina",
        text: "Função exclusiva para Clientes do Malha Fina do Contador. Acompanhe o status de processamento das DIRPF.",
      },
    ],
    news: [
      {
        img: "https://www.sebrae.com.br/Sebrae/Portal%20Sebrae/Artigos/Imagens/xImagem_artigo_fluxo-caixa-o-que-como-implantar.png.pagespeed.ic.qwTckSJPQ1.png",
        title: "O que é o fluxo de caixa e como aplicá-lo no seu negócio - Sebrae",
        description: "Aprenda a avaliar as entradas e saídas de recursos e a disponibilidade de capital de giro na sua empresa",
        link: "https://www.sebrae.com.br/sites/PortalSebrae/artigos/fluxo-de-caixa-o-que-e-e-como-implantar,b29e438af1c92410VgnVCM100000b272010aRCRD",
      },
      {
        img: "https://blog.vittaresidencial.com.br/wp-content/uploads/2019/05/organizardocumentos.jpg",
        title: "8 dicas para organizar documentos e não perdê-los nunca mais",
        description: "E são muitos os registros que precisam ser catalogados e guardados, como comprovantes de pagamentos, documentos trabalhistas, itens pessoais, entre outros",
        link: "https://blog.vittaresidencial.com.br/8-dicas-para-organizar-documentos-e-nao-perde-los-nunca-mais/",
      },
    ],
  }),

}
</script>
