<template>
  <v-sheet
    :dark="dark" :color="color"
    class="
      px-6
      d-flex align-center justify-center
    "
    :style="sheetStyle"
  >
    <div
      class="flex-grow-1 d-flex align-center"
      :style="{
        'max-width': '1200px',
        'min-height': small ? '0':height,
      }"
      :class="{
        'flex-row-reverse': !imgRight,
        'flex-column': isMobile,
      }"
    >
      <div class="flex-grow-1 py-6">
        <div
          v-if="title"
          class="pb-2 font-weight-black"
          :class="{
            'primary--text': !dark,
            'text-center': center,
            'text-h4': isMobile,
            'text-h3': !isMobile,
          }"
        >
          {{ title }}
        </div>
        <slot />
      </div>
      <template v-if="!!img && (!isMobile || imgMobile == 'bottom')">
        <v-img
          :width="isMobile ? '300px':imgWidth"
          :height="(isMobile || imgMobile != 'bottom') ? undefined:height"
          :position="imgMobile == 'bottom' ? 'bottom':'center'"
          :src="$assets(img)"
          :class="
            (!isMobile ? (imgRight ? 'ml-8':'mr-8'):'')
            + ' '
            + imgClass
          "
          contain
        />
      </template>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "Content",
  props: {
    color: {required: true},
    title: {default: undefined},
    dark: {default: false, type: Boolean},
    center: {default: false, type: Boolean},
    img: {default: undefined},
    small: {default: false, type: Boolean},
    'img-right': {default: false, type: Boolean},
    'img-class': {default: undefined},
    'img-mobile': {default: 'hidden'},
    sheetStyle: {default: undefined},
    height: {default: '500px'},
    imgWidth: {default: '450px'},
  },

  computed: {
    isMobile(){
      return this.$vuetify.breakpoint.width < 800;
    },
  },

}
</script>
