<template>
  <v-footer color="primary white--text" class="d-flex justify-center py-0">
    <v-icon small class="white--text mr-2">mdi-copyright</v-icon>
    <span class="overline">
      Almeida Technologies LTDA — {{ new Date().getFullYear() }}</span
    >
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({}),
};
</script>
